import {
  TGeocodeResultItem,
  THouseDetailRes,
  THouseListItem,
  THouseSavePayload,
} from "../lib/types/house.type";
import axios from "./axios";

export const api_houseList = async () => {
  const res = await axios.get("/house/adm/list");
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as { list: THouseListItem[] };
};

export const api_deleteHouses = async (ids: number[]) => {
  const res = await axios.delete("/house", { data: { ids } });
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as { list: THouseListItem[] };
};

export const api_kakaoGeocode = async (address: string) => {
  const url = `/house/geocode?address=${address}`;
  const res = await axios.get(url);
  console.log("res :: ", res.data);

  if (!res) return;
  if (!res.data.success) return;

  return res.data.data.result as TGeocodeResultItem[];
};

export const api_saveHouse = async (payload: THouseSavePayload) => {
  const res = await axios.post("/house", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data.house as { id: number };
};

export const api_houseDetail = async (houseId: number) => {
  const res = await axios.get(`/house/adm/${houseId}`);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as THouseDetailRes;
};
export const api_saveHouseContents = async (
  houseId: number,
  contents: { title: string; content: string }[]
) => {
  const res = await axios.post(`/house/${houseId}/contents`, { contents });
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as true;
};

export const api_saveHouseLinks = async (
  houseId: number,
  links: { title: string; url: string }[]
) => {
  const res = await axios.post(`/house/${houseId}/links`, { links });
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as true;
};
