import { TWithdrawalListItem } from "../lib/types/withdrawal.type";
import axios from "./axios";

export const api_withdrawalList = async () => {
  const res = await axios.get("/withdrawal/adm/applyHistory");
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as { list: TWithdrawalListItem[] };
};

export const api_withdrawalResolve = async (id: number) => {
  const res = await axios.post(`/withdrawal/${id}/resolve`);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as true;
};
