import React, { useEffect, useState } from "react";
import { api_deleteHouses, api_houseList } from "../api/house";
import { THouseListItem } from "../lib/types/house.type";
import { useNavigate } from "react-router-dom";
import { TQnaListItem } from "../lib/types/qna.type";
import { api_qnaList } from "../api/qna";
import { api_tradeHistoryList } from "../api/ticket";
import { TTradeHistoryListItem } from "../lib/types/ticket.type";

const TradeHistoryListPage = () => {
  const navigate = useNavigate();

  const [list, setList] = useState<TTradeHistoryListItem[]>([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    api_tradeHistoryList().then((fetchedList) => {
      if (!fetchedList) return;
      setList(fetchedList);
    });
  };

  return (
    <main>
      <h1>숙박권 거래내역</h1>
      <h4>
        거래횟수가 1인 항목은 최초 구매자가 다른 유저에게 판매하지 않은
        항목입니다.
      </h4>

      <table className="w-full table-auto mt-5">
        <thead className="text-sm font-semibold text-left text-gray-800 border-b pb-2">
          <tr>
            <th>No.</th>
            <th>숙소명</th>
            <th>객실명</th>
            <th>방문일자</th>
            <th>거래횟수</th>
          </tr>
        </thead>

        <tbody>
          {list.map((history, i) => (
            <tr
              key={i}
              onClick={(e) => {
                navigate(`/trade/${history.initialTicketId}`);
              }}
            >
              <td>{list.length - i}</td>
              <td>{history.houseName}</td>
              <td>{history.roomName}</td>
              <td>{history.visitDate}</td>
              <td>{history.tradeCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};

export default TradeHistoryListPage;
