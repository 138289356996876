import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TUserListItem } from "../lib/types/user.type";
import { printFormattedDate } from "../lib/utils";
import { api_userList } from "../api/user";
import { TWithdrawalListItem } from "../lib/types/withdrawal.type";
import { api_withdrawalList, api_withdrawalResolve } from "../api/withdrawal";

const WithdrawalListPage = () => {
  const navigate = useNavigate();

  const [list, setList] = useState<TWithdrawalListItem[]>([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    api_withdrawalList().then((res) => {
      if (!res) return;
      setList(res.list);
    });
  };

  const resolve = (id: number) => {
    if (!window.confirm("해당 계좌로 지급 완료하셨습니까?")) return;

    api_withdrawalResolve(id).then((res) => {
      if (!res) return;

      alert("지급완료 처리되었습니다.");
      fetchList();
    });
  };

  return (
    <main>
      <h1>예치금 인출신청 내역</h1>

      <table className="w-full table-auto mt-5">
        <thead className="text-sm font-semibold text-left text-gray-800 border-b pb-2">
          <tr>
            <th>No.</th>
            <th>유저 이름</th>
            <th>휴대폰번호</th>
            <th>은행</th>
            <th>계좌</th>
            <th>예금주</th>
            <th>지급여부</th>
          </tr>
        </thead>

        <tbody>
          {list.map((item, i) => (
            <tr
              key={i}
              // onClick={(e) => {
              //   navigate(`/house/${house.id}`);
              // }}
            >
              <td>{list.length - i}</td>
              <td>{item.userName}</td>
              <td>{item.phone}</td>
              <td>{item.bank}</td>
              <td>{item.account}</td>
              <td>{item.accountOwner}</td>
              <td>
                {item.resolvedAt ? (
                  "지급됨"
                ) : (
                  <button
                    className={`bg-blue-400 text-white`}
                    onClick={() => {
                      resolve(item.id);
                    }}
                  >
                    지급완료
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};

export default WithdrawalListPage;
