import axios from "./axios";

export const api_mainImageList = async () => {
  const res = await axios.get("/image/adm/main");
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data.images as { id: number; url: string }[];
};
export const api_saveMainImages = async (
  images: { id: number; url: string; isNew: boolean }[]
) => {
  const res = await axios.post("/image/adm/main", { images });
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as true;
};
