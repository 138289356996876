import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { atom__me } from "../lib/recoil";

const MainPage = () => {
  const r__me = useRecoilValue(atom__me);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("me: ", r__me);
    if (r__me) {
      navigate("/house");
    } else {
      navigate("/login");
    }
  }, [r__me, navigate]);

  return <div className="p-4"></div>;
};

export default MainPage;
