import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { atom__me } from "../lib/recoil";
import axios from "axios";
import { api_signin } from "../api/user";

const LoginPage = () => {
  const [r__me, r__setMe] = useRecoilState(atom__me);

  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (r__me) navigate(-1);
  }, [r__me, navigate]);

  const login = useCallback(
    async (e: any) => {
      e.preventDefault();

      api_signin({ uid: id, pw }).then((res) => {
        if (!res) return;

        const { adm, accessToken } = res;
        r__setMe(adm);
        axios.defaults.headers.common = {
          Authorization: `Bearer ${accessToken}`,
        };

        localStorage.setItem("at", accessToken);

        navigate("/");
      });
    },
    [id, navigate, pw]
  );

  return (
    <section className="h-full">
      <div className="flex items-center justify-center mt-4">
        <div className="p-4 bg-gray-100 rounded w-96">
          <h1 className="mb-2 text-2xl text-center">로그인</h1>
          <div className="">
            <form className="flex flex-col" onSubmit={login}>
              <input
                type="text"
                placeholder="아이디"
                className="p-2 mb-2 border rounded"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
              <input
                autoComplete="off"
                type="password"
                placeholder="비밀번호"
                className="p-2 mb-4 border rounded"
                value={pw}
                onChange={(e) => setPw(e.target.value)}
              />
              <button className="py-2 text-lg text-white bg-red-400 rounded">
                로그인
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
