import {
  TRoomDetailRes,
  TRoomListItem,
  TRoomSavePayload,
} from "../lib/types/room.type";
import axios from "./axios";

export const api_deleteRooms = async (ids: number[], houseId: number) => {
  const res = await axios.delete("/room", { data: { ids, houseId } });
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as { list: TRoomListItem[] };
};

export const api_saveRoom = async (payload: TRoomSavePayload) => {
  const res = await axios.post("/room", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data.room as { id: number };
};

export const api_roomDetail = async (roomId: number) => {
  const res = await axios.get(`/room/adm/${roomId}`);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as TRoomDetailRes;
};
export const api_saveRoomContents = async (
  roomId: number,
  contents: { title: string; content: string }[]
) => {
  const res = await axios.post(`/room/${roomId}/contents`, { contents });
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as true;
};
export const api_saveRoomCosts = async (
  roomId: number,
  costs: {
    startDate: string;
    endDate: string;
    weekdayCost: number;
    weekendCost: number;
  }[]
) => {
  const res = await axios.post(`/room/${roomId}/costs`, { costs });
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as true;
};
