export const printFormattedDate = (
  date: Date | null,
  options?: { seperator?: string }
) => {
  if (!date) return "";

  const seperator = options?.seperator ?? "/";

  const y = date.getFullYear();
  const M = (date.getMonth() + 1 + "").padStart(2, "0");
  const d = (date.getDate() + "").padStart(2, "0");
  return `${y}${seperator}${M}${seperator}${d}`;
};

export const printFormattedTime = (date: Date | null) => {
  if (!date) return "";

  const h = (date.getHours() + "").padStart(2, "0");
  const m = (date.getMinutes() + "").padStart(2, "0");
  return `${h}:${m}`;
};
