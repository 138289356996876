import React, { useEffect, useMemo, useState } from "react";
import { api_deleteHouses, api_houseList } from "../api/house";
import { THouseListItem } from "../lib/types/house.type";
import { useNavigate, useParams } from "react-router-dom";
import { TQnaListItem } from "../lib/types/qna.type";
import { api_qnaList } from "../api/qna";
import { api_tradeHistoryDetail, api_tradeHistoryList } from "../api/ticket";
import { TTradeHistoryListItem } from "../lib/types/ticket.type";

const TradeHistoryDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [summary, setSummary] = useState<TTradeHistoryListItem | null>(null);
  const [histories, setHistories] = useState<
    {
      date: string;
      owner: { id: number; name: string }; // 먼저 구매한 유저 (이하 유저A)
      buyer: { id: number; name: string } | null; // 유저A가 판매한 유저. 즉, 새로운 구매자 (이하 유저B)
      amount: string; // 유저A가 구매한 가격
      soldAmount: string; // 유저B가 유저A로부터 구매한 가격
    }[]
  >([]);

  const initialTicketId = useMemo(() => {
    const n = Number(params.initialTicketId);
    if (!n) {
      alert("비정상적인 접근입니다.");
      navigate("/trade");
      return;
    }

    return n;
  }, [params, navigate]);

  useEffect(() => {
    if (!initialTicketId) return;

    api_tradeHistoryDetail(initialTicketId).then((res) => {
      if (!res) return;

      setSummary(res.summary);
      setHistories(res.histories);
    });
  }, []);

  return (
    <main>
      <h1>숙박권 거래내역</h1>

      <div className="form-section">
        <h2>숙박권 정보</h2>

        <div className="flex mb-10">
          <div className="mr-8">
            <div className="my-2">
              <label className="label-default">숙소명</label>
            </div>
            <p>{summary?.houseName ?? "-"}</p>
          </div>

          <div className="mr-8">
            <div className="my-2">
              <label className="label-default">객실명</label>
            </div>
            <p>{summary?.roomName ?? "-"}</p>
          </div>

          <div>
            <div className="my-2">
              <label className="label-default">방문일</label>
            </div>
            <p>{summary?.visitDate ?? "-"}</p>
          </div>
        </div>
      </div>

      <div className="form-section">
        <h2>거래내역</h2>
        <h4>
          유저이름 표기 시, 동명이인을 고려하여 "[유저 고유번호]유저이름"의
          형식을 사용합니다.
        </h4>

        <table className="w-full table-auto mt-5">
          <thead className="text-sm font-semibold text-left text-gray-800 border-b pb-2">
            <tr>
              <th>No.</th>
              <th>구매일시</th>
              <th></th>
              <th>내용</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {histories.map((history, i) => (
              <tr key={i}>
                <td>{histories.length - i}</td>
                <td>{history.date}</td>
                <td>
                  <span className="font-bold">
                    [{history.owner.id}]{history.owner.name}
                  </span>
                  님이 <span className="font-bold">{history.amount}</span>에
                  구매
                </td>
                <td>→</td>
                <td>
                  {history.buyer ? (
                    <>
                      <span className="font-bold">
                        [{history.buyer.id}]{history.buyer.name}
                      </span>
                      님에게{" "}
                      <span className="font-bold">{history.soldAmount}</span>에
                      판매
                    </>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default TradeHistoryDetailPage;
