import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api_qnaAnswer, api_qnaDetail } from "../api/qna";
import { TQnaDetailRes } from "../lib/types/qna.type";
import { Button } from "@headlessui/react";

const QnaDetailPage = () => {
  const params = useParams();

  const [detail, setDetail] = useState<TQnaDetailRes | null>(null);
  const [answer, setAnswer] = useState("");

  const qnaId = useMemo(() => {
    return Number(params.id);
  }, [params]);

  useEffect(() => {
    api_qnaDetail(qnaId).then((res) => {
      if (!res) return;
      console.log("qna:: ", res);
      setDetail(res);
    });
  }, [params]);

  useEffect(() => {
    if (!detail) return;
    setAnswer(detail.qna.answer ?? "");
  }, [detail]);

  const saveAnswer = () => {
    api_qnaAnswer(qnaId, answer).then((res) => {
      if (!res) return;
      alert("저장되었습니다.");
    });
  };

  return (
    <main>
      <h1>{detail?.qna.subject}</h1>

      <div className="flex mb-10">
        <div className="mr-8">
          <div className="my-2">
            <label className="label-default">유저이름</label>
          </div>
          <p>{detail?.user.name}</p>
        </div>

        <div>
          <div className="my-2">
            <label className="label-default">휴대폰번호</label>
          </div>
          <p>{detail?.user.phoneDec ?? "-"}</p>
        </div>
      </div>

      <div className="my-2">
        <label className="label-default">내용</label>
      </div>
      <div className="form-section mb-20">
        <pre>{detail?.qna.content}</pre>
      </div>

      <div className="my-2">
        <label className="label-default">답변</label>
        <button
          className={`${
            answer.length ? "bg-blue-400" : "bg-gray-400"
          } text-white ml-2`}
          onClick={saveAnswer}
          disabled={!answer.length}
        >
          저장
        </button>
      </div>
      <textarea
        className="w-full h-[200px] border px-2 py-2"
        //   style={{
        //     width: '100%',
        //   }}
        placeholder="답변을 작성해주세요"
        value={answer}
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
      />
    </main>
  );
};

export default QnaDetailPage;
