import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import {
  faArrowRightFromBracket,
  faHospital,
  faIdCard,
  faUserDoctor,
  faList,
  faTruckMedical,
  faHouse,
  faTableList,
  faHeadset,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { sideBarItem } from "./items";
import { useRecoilState } from "recoil";
import { atom__me } from "../../lib/recoil";
import SideBarItem from "./SideBarItem";
import axios from "axios";

const SideBar = () => {
  const [r__me, r__setMe] = useRecoilState(atom__me);
  const pathName = useLocation().pathname;

  const logout = async () => {
    await axios.post("/user/adm/logout", { withCredentials: true });
    axios.defaults.headers.common.authorization = false;
    localStorage.removeItem("at");
    r__setMe(null);
  };

  return (
    <section className="relative w-64 pt-20 border-r bg-white ">
      <ul className="">
        {sideBarItem.map((item) => (
          <SideBarItem
            key={item.id}
            item={item}
            isActive={pathName.startsWith(item.path)}
          />
        ))}
      </ul>
      {!!r__me && (
        <button
          onClick={logout}
          className="absolute bottom-4 right-4 text-lg p-4"
        >
          <FontAwesomeIcon className="mr-2" icon={faArrowRightFromBracket} />
          로그아웃
        </button>
      )}
    </section>
  );
};

export default SideBar;
