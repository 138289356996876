import { TUploadedImage } from "../lib/types/common.type";
import axios from "./axios";

export const api_uploadImgToS3 = async (file: File) => {
  const filename =
    Date.now() + "." + file.name.split(".")[file.name.split(".").length - 1];

  const { data } = await axios.get(`/image/signedurl?filename=${filename}`);
  if (!data) return;
  if (!data.success) return alert(data.errorMessage);

  const signedUrl = data.data.url;

  const res2 = await fetch(
    new Request(signedUrl, {
      method: "PUT",
      body: file,
      headers: new Headers({
        "Content-Type": "image/*",
      }),
    })
  );

  console.log("s3 result: ", await res2.text());

  if (res2.status !== 200)
    return alert("죄송합니다. 잠시 후 다시 이용해 주세요.");

  return `https://goochoori.s3.ap-northeast-2.amazonaws.com/ssnipani-images/${filename}`;
};
export const api_saveUploadedImg = async (url: string) => {
  const { data } = await axios.post(`/image`, { url });
  if (!data) return;
  if (!data.success) return alert(data.errorMessage);

  return data.data.createdId as number;
};
