import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HouseTag from "../components/HouseTag";
import HouseImage from "../components/HouseImage";
import { api_saveUploadedImg, api_uploadImgToS3 } from "../api/common";
import {
  api_houseDetail,
  api_kakaoGeocode,
  api_saveHouse,
  api_saveHouseContents,
  api_saveHouseLinks,
} from "../api/house";
import { TGeocodeResultItem, THouseSavePayload } from "../lib/types/house.type";
import Editor from "../components/Editor";
import { TRoomListItem } from "../lib/types/room.type";
import { api_deleteRooms } from "../api/room";

const HouseFormPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [title, setTitle] = useState("");

  const [houseId, setHouseId] = useState<number | null>(null);
  const [mode, setMode] = useState<"create" | "update">("create");

  const [houseName, setHouseName] = useState("");
  const [houseAddress, setHouseAddress] = useState("");
  const [houseIntroduce, setHouseIntroduce] = useState("");
  const [currentTag, setCurrentTag] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [logoUrl, setLogoUrl] = useState("");
  const [selectedLogo, setSelectedLogo] = useState<File | null>(null);

  const [geocodeResult, setGeocodeResult] = useState<TGeocodeResultItem[]>([]);
  const [selectedGeocode, setSelectedGeocode] =
    useState<TGeocodeResultItem | null>(null);

  // room 관련 state
  const [selectedRoomIds, setSelectedRoomIds] = useState<number[]>([]);
  const [rooms, setRooms] = useState<TRoomListItem[]>([]);

  // 앱의 상세화면 상단 스와이퍼에 표시할 이미지
  const [swiperImages, setSwiperImages] = useState<
    (
      | { type: "already"; id: number; url: string }
      | { type: "new"; file: File; data: string }
    )[]
  >([]);

  // 앱의 상세화면 본문에 표시할 이미지
  const [contentImages, setContentImages] = useState<
    (
      | { type: "already"; id: number; url: string }
      | { type: "new"; file: File; data: string }
    )[]
  >([]);

  const [detailContents, setDetailContents] = useState<
    { id?: number; title: string; content: string }[]
  >([]);

  const [links, setLinks] = useState<{ title: string; url: string }[]>([]);

  const refInputImage = useRef<HTMLInputElement>(null);
  const refInputImageUsage = useRef<"logo" | "img_add" | "img_change" | "none">(
    "logo"
  );
  const refInputImagePosition = useRef<"swiper" | "content">("swiper");
  const refChangingImageIdx = useRef(-1);
  const refGeocodeTimerId = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    /**
     * [추가페이지]
     * params = {id: '0'}
     * mode = 'create'
     * houseId = null --기본정보 저장 시--> number
     * title = '숙소 등록'
     *
     * [추가페이지 - 기본정보 저장 후]
     * mode = 'update'
     * houseId = number
     * title = '숙소 정보'
     *
     * [편집페이지]
     * params = {id: '1'}
     * mode = 'update'
     * houseId = 1
     * title = '숙소 정보'
     */

    if (params.id && params.id !== "0") {
      const houseIdNumber = Number(params.id);
      setHouseId(houseIdNumber);
      setMode("update");
      setTitle("숙소 정보");

      fetchDetail(houseIdNumber);
    } else {
      setTitle("숙소 등록");
    }
  }, [params]);

  const fetchDetail = (id: number) => {
    api_houseDetail(id).then((res) => {
      if (!res) return;

      const {
        house,
        swiperImages: swiperImagesRes,
        contentImages: contentImagesRes,
        contents,
        rooms,
        links: linksRes,
      } = res;

      setSelectedGeocode({
        lat: house.lat,
        lng: house.lng,
        address: house.address,
        region1: house.region1,
        region2: house.region2,
      });

      setHouseName(house.name);
      setHouseAddress(house.address);
      setHouseIntroduce(house.introduce);
      setLogoUrl(house.logoImgUrl);
      setTags(house.tag.split(","));

      setSwiperImages(
        swiperImagesRes.map((img) => {
          return {
            type: "already",
            id: img.id,
            url: img.url,
          };
        })
      );
      setContentImages(
        contentImagesRes.map((img) => {
          return {
            type: "already",
            id: img.id,
            url: img.url,
          };
        })
      );

      setDetailContents(contents);

      setRooms(rooms);

      console.log("links: ", linksRes);
      setLinks(linksRes);
    });
  };

  const onChangeAddress = (v: string) => {
    setHouseAddress(v);

    if (!v || !v.trim()) {
      if (refGeocodeTimerId.current !== null) {
        clearTimeout(refGeocodeTimerId.current);
        refGeocodeTimerId.current = null;
      }

      setGeocodeResult([]);
      return;
    }

    if (refGeocodeTimerId.current !== null) {
      clearTimeout(refGeocodeTimerId.current);
      refGeocodeTimerId.current = null;
    }

    refGeocodeTimerId.current = setTimeout(() => {
      api_kakaoGeocode(v.trim()).then((res) => {
        if (!res) return;
        setGeocodeResult(res);
      });
    }, 1000);
  };

  const save = async () => {
    if (!houseName) return alert("모든 란을 입력해주세요.");
    if (!houseAddress) return alert("모든 란을 입력해주세요.");
    if (!tags.length) return alert("하나 이상의 태그를 입력해주세요.");
    if (!logoUrl) return alert("로고 이미지를 등록해주세요.");
    if (!houseIntroduce) return alert("모든 란을 입력해주세요.");
    if (!swiperImages.length)
      return alert("하나 이상의 이미지를 등록해주세요.");
    if (!selectedGeocode) return alert("올바른 주소를 선택해주세요.");

    const joinedTag = tags.join(",");

    const uploadedImgs: { id: number; url: string; isNew: boolean }[] = [];
    for (const img of swiperImages) {
      if (img.type === "new") {
        const url = await api_uploadImgToS3(img.file);
        if (!url) return;
        const createdId = await api_saveUploadedImg(url);
        if (!createdId) return;

        uploadedImgs.push({ id: createdId, url, isNew: true });
      } else uploadedImgs.push({ id: img.id, url: img.url, isNew: false });
    }

    const uploadedContentImgs: { id: number; url: string; isNew: boolean }[] =
      [];
    for (const img of contentImages) {
      if (img.type === "new") {
        const url = await api_uploadImgToS3(img.file);
        if (!url) return;
        const createdId = await api_saveUploadedImg(url);
        if (!createdId) return;

        uploadedContentImgs.push({ id: createdId, url, isNew: true });
      } else
        uploadedContentImgs.push({ id: img.id, url: img.url, isNew: false });
    }

    const payload: THouseSavePayload = {
      name: houseName,
      address: houseAddress,
      lat: selectedGeocode.lat,
      lng: selectedGeocode.lng,
      introduce: houseIntroduce,
      logoImgUrl: logoUrl,
      tag: joinedTag,

      swiperImages: uploadedImgs,
      contentImages: uploadedContentImgs,
      region1: selectedGeocode.region1,
      region2: selectedGeocode.region2,
    };

    if (houseId) payload.id = houseId;

    // 로고 upload
    if (selectedLogo) {
      const uploadedUrl = await api_uploadImgToS3(selectedLogo);
      if (uploadedUrl) payload.logoImgUrl = uploadedUrl;
    }

    console.log("payload: ", payload);

    const res = await api_saveHouse(payload);
    if (!res) return;

    alert("저장되었습니다.");

    setHouseId(res.id);
    setMode("update");
    setTitle("숙소 정보");
    setSwiperImages(
      uploadedImgs.map((img) => ({ type: "already", id: img.id, url: img.url }))
    );
    setContentImages(
      uploadedContentImgs.map((img) => ({
        type: "already",
        id: img.id,
        url: img.url,
      }))
    );
  };

  const saveContents = () => {
    if (!houseId) return alert("기본 정보를 먼저 저장해주세요.");
    if (!detailContents.length)
      return alert("하나 이상의 상세설명을 작성해주세요.");
    if (detailContents.find((c) => !c.content))
      return alert("상세설명의 내용은 비워둘 수 없습니다.");

    console.log("detailContents::", detailContents);

    api_saveHouseContents(houseId, detailContents).then((res) => {
      if (!res) return;
      alert("저장되었습니다.");
    });
  };

  const saveLinks = () => {
    if (!houseId) return alert("기본 정보를 먼저 저장해주세요.");
    if (links.find((l) => !l.title || !l.url))
      return alert("모든 란을 입력해주세요.");
    if (links.find((l) => !l.url.startsWith("https://")))
      return alert("링크 주소는 https:// 로 시작해야 합니다.");

    api_saveHouseLinks(houseId, links).then((res) => {
      if (!res) return;
      alert("저장되었습니다.");
    });
  };

  const selectAddress = (geo: TGeocodeResultItem) => {
    setSelectedGeocode(geo);
    setGeocodeResult([]);
    setHouseAddress(geo.address);
  };

  const deleteRoom = () => {
    if (!houseId) return;
    if (!window.confirm("정말 삭제하시겠습니까?")) return;

    api_deleteRooms(selectedRoomIds, houseId).then((res) => {
      if (!res) return;
      setRooms(res.list);
      alert("삭제되었습니다.");
      setSelectedRoomIds([]);
    });
  };

  return (
    <main>
      <h1 style={{ marginBottom: "20px" }}>{title}</h1>

      {/* 기본 정보 */}
      <div className={"form-section"}>
        <div className="flex justify-between items-center">
          <h2>기본 정보</h2>
          <button className={`bg-blue-400 text-white`} onClick={save}>
            저장
          </button>
        </div>
        <h4>숙소를 선택했을 때 가장 먼저 보여지는 정보입니다.</h4>

        <div style={{ marginTop: "20px" }}>
          <label htmlFor="house_name">숙소 이름</label>
          <input
            className="border px-2 py-1 w-full"
            type="text"
            id="house_name"
            name="house_name"
            value={houseName}
            onChange={(e) => setHouseName(e.target.value)}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <label htmlFor="house_address">주소</label>
          <div style={{ position: "relative" }}>
            <input
              className="border px-2 py-1 w-full"
              type="text"
              id="house_address"
              name="house_address"
              value={houseAddress}
              placeholder="주소 입력 시 아래 나오는 검색결과 중 선택해주세요."
              onChange={(e) => onChangeAddress(e.target.value)}
            />

            {geocodeResult.length > 0 && (
              <div style={{ position: "absolute", top: "100%", width: "100%" }}>
                {geocodeResult.map((geo, i) => {
                  return (
                    <div
                      key={i}
                      className="geocode-result"
                      onClick={() => selectAddress(geo)}
                    >
                      <span>{geo.region1}</span> {geo.address}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label htmlFor="house_tag">숙소 태그</label>
          <input
            className="border px-2 py-1 my-2 w-full"
            // style={{ width: "300px" }}
            type="text"
            id="house_tag"
            name="house_tag"
            value={currentTag}
            onChange={(e) =>
              setCurrentTag(e.target.value.replaceAll(" ", "").substring(0, 10))
            }
            onKeyPress={(e) => {
              // NOTE: onKeyDown을 사용하면 한글 입력 직후 enter를 치면 이벤트가 두번 일어나고, 이 때 두번째 발생하는 이벤트에서는 currentTag 값이 이상하게 넘어옴. -> onKeyPress로 바꾸니 해결됨.
              if (e.code !== "Enter") return;
              if (!currentTag.length) return;

              console.log("currentTag: ", currentTag);

              const already = tags.find((t) => t === currentTag);
              if (!already) tags.push(currentTag);
              setCurrentTag("");
            }}
            placeholder="엔터를 누르면 태그가 추가됩니다. (띄어쓰기 불가, 최대 10글자)"
            maxLength={10}
          />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {tags.map((tag, i) => {
              return (
                <HouseTag
                  key={i}
                  text={tag}
                  onClick={() => {
                    console.log("E1");
                    setTags((prev) => {
                      const copied = prev.slice();
                      copied.splice(i, 1);
                      return copied;
                    });
                  }}
                />
              );
            })}
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label htmlFor="house_introduce">홍보문구</label>
          <input
            className="border px-2 py-1 w-full"
            type="text"
            id="house_introduce"
            name="house_introduce"
            value={houseIntroduce}
            onChange={(e) => setHouseIntroduce(e.target.value)}
            placeholder="고객에게 보여지는 홍보문구입니다."
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>로고</label>
          <div
            className="house-image-container house-image-container-plus"
            onClick={() => {
              refInputImageUsage.current = "logo";
              refInputImage.current?.click();
            }}
          >
            {!!logoUrl && (
              <img
                src={logoUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>상단 이미지</label>
          <div className="horizontal-scroll-container">
            {swiperImages.map((swiperImg, i) => {
              return (
                <HouseImage
                  key={i}
                  src={
                    swiperImg.type === "already"
                      ? swiperImg.url
                      : swiperImg.data
                  }
                  onClickChange={() => {
                    refChangingImageIdx.current = i;

                    refInputImagePosition.current = "swiper";
                    refInputImageUsage.current = "img_change";
                    refInputImage.current?.click();
                  }}
                  onClickDelete={() => {
                    setSwiperImages((prev) => {
                      const copied = prev.slice();
                      copied.splice(i, 1);

                      return copied;
                    });
                  }}
                  style={{ marginRight: "10px" }}
                />
              );
            })}

            <div
              className="house-image-container house-image-container-plus"
              style={{
                width: "130px",
                height: "130px",
              }}
              onClick={() => {
                refInputImagePosition.current = "swiper";
                refInputImageUsage.current = "img_add";
                refInputImage.current?.click();
              }}
            ></div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>본문 이미지 (최대 10장)</label>
          <div className="horizontal-scroll-container">
            {contentImages.map((contentImg, i) => {
              return (
                <HouseImage
                  key={i}
                  src={
                    contentImg.type === "already"
                      ? contentImg.url
                      : contentImg.data
                  }
                  onClickChange={() => {
                    refChangingImageIdx.current = i;

                    refInputImagePosition.current = "content";
                    refInputImageUsage.current = "img_change";
                    refInputImage.current?.click();
                  }}
                  onClickDelete={() => {
                    setContentImages((prev) => {
                      const copied = prev.slice();
                      copied.splice(i, 1);

                      return copied;
                    });
                  }}
                  style={{ marginRight: "10px" }}
                />
              );
            })}

            {contentImages.length < 10 && (
              <div
                className="house-image-container house-image-container-plus"
                style={{
                  width: "130px",
                  height: "130px",
                }}
                onClick={() => {
                  refInputImagePosition.current = "content";
                  refInputImageUsage.current = "img_add";
                  refInputImage.current?.click();
                }}
              ></div>
            )}
          </div>
        </div>
      </div>

      {/* 상세 설명 */}
      <div className={"form-section"}>
        <div className="flex justify-between items-center">
          <h2>상세 설명</h2>
          <button className={`bg-blue-400 text-white`} onClick={saveContents}>
            저장
          </button>
        </div>
        <h4 style={{ marginBottom: "30px" }}>
          제공 이벤트 등 제공되는 상세정보를 입력해주세요.
        </h4>

        {detailContents.map((c, i) => {
          return (
            <div className="editor-container" key={i}>
              <div>
                <label>제목</label>
                <button
                  className={`bg-red-400 text-white ml-4`}
                  onClick={() => {
                    setDetailContents((prev) => {
                      const copied = prev.slice();
                      copied.splice(i, 1);
                      return copied;
                    });
                  }}
                >
                  삭제
                </button>
              </div>
              <input
                className="border px-2 py-1 my-2 w-full"
                type="text"
                name="house_content_title"
                value={c.title}
                onChange={(e) => {
                  setDetailContents((prev) => {
                    const copied = prev.slice();
                    copied[i].title = e.target.value;
                    return copied;
                  });
                }}
              />

              <div className="my-2">
                <label>내용</label>
              </div>
              <Editor
                id={`#editor${i}`}
                value={c.content}
                onChange={(v: string) => {
                  setDetailContents((prev) => {
                    const copied = prev.slice();
                    copied[i].content = v;
                    return copied;
                  });
                }}
              />
            </div>
          );
        })}
        <button
          className="bg-blue-400 text-white mt-5"
          onClick={() => {
            setDetailContents((prev) => [...prev, { title: "", content: "" }]);
          }}
        >
          추가
        </button>
      </div>

      {/* 홍보 링크 */}
      <div className={"form-section"}>
        <div className="flex justify-between items-center">
          <h2>홍보 링크</h2>
          <button className={`bg-blue-400 text-white`} onClick={saveLinks}>
            저장
          </button>
        </div>
        <h4>앱에서 숙소 상세화면에 표시되는 SNS/BLOG 등의 외부링크입니다.</h4>

        {links.map((link, i) => {
          return (
            <div className="flex items-center editor-container" key={i}>
              <div className="mr-4">
                <label>이름</label>
                <input
                  type="text"
                  style={{ display: "block" }}
                  className="border px-2 py-1 my-2 w-[200px]"
                  value={link.title}
                  onChange={(e) => {
                    setLinks((prev) => {
                      const copied = prev.slice();
                      copied[i].title = e.target.value;
                      return copied;
                    });
                  }}
                />
              </div>

              <div className="mr-4">
                <label>링크 주소</label>
                <input
                  type="text"
                  style={{ display: "block" }}
                  className="border px-2 py-1 my-2 w-[400px]"
                  value={link.url}
                  onChange={(e) => {
                    setLinks((prev) => {
                      const copied = prev.slice();
                      copied[i].url = e.target.value;
                      return copied;
                    });
                  }}
                />
              </div>

              <button
                className={`bg-red-400 text-white ml-4`}
                onClick={() => {
                  setLinks((prev) => {
                    const copied = prev.slice();
                    copied.splice(i, 1);
                    return copied;
                  });
                }}
              >
                삭제
              </button>
            </div>
          );
        })}

        <button
          className="bg-blue-400 text-white mt-5"
          onClick={() => {
            setLinks((prev) => [...prev, { title: "", url: "" }]);
          }}
        >
          추가
        </button>
      </div>

      {/* 객실 목록 */}
      <div className="form-section">
        <div className="flex justify-between items-center">
          <h2>객실 목록</h2>
          <div>
            <button
              className={`bg-blue-400 text-white mr-4`}
              onClick={() => {
                if (!houseId)
                  return alert("숙소의 기본 정보를 먼저 저장해주세요.");

                navigate(`/house/${houseId}/room/0`);
              }}
            >
              추가
            </button>
            <button
              className={`${
                selectedRoomIds.length === 0 ? "bg-gray-400" : "bg-red-400"
              } text-white`}
              disabled={selectedRoomIds.length === 0}
              onClick={deleteRoom}
            >
              삭제
            </button>
          </div>
        </div>

        <table className="w-full table-auto mt-5">
          <thead className="text-sm font-semibold text-left text-gray-800 border-b pb-2">
            <tr>
              <th>선택</th>
              <th>객실 명</th>
              <th>인원 (기준/최대)</th>
              <th>평수</th>
              <th>체크인 / 체크아웃</th>
            </tr>
          </thead>

          <tbody>
            {rooms.map((room) => (
              <tr
                key={room.id}
                onClick={(e) => {
                  navigate(`/house/${houseId}/room/${room.id}`);
                }}
              >
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.currentTarget.checked;
                      if (checked) {
                        setSelectedRoomIds((prev) => {
                          return [...prev, room.id];
                        });
                      } else {
                        setSelectedRoomIds((prev) => {
                          const copied = prev.slice();
                          const idx = copied.findIndex((i) => i === room.id);
                          copied.splice(idx, 1);
                          return copied;
                        });
                      }
                    }}
                  />
                </td>
                <td>{room.name}</td>
                <td>
                  {room.peoplePivot}명 / {room.peopleLimit}명
                </td>
                <td>{room.roomSize}평</td>
                <td>
                  {room.checkinTime} / {room.checkoutTime}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <input
        type="file"
        ref={refInputImage}
        name="house_logo"
        style={{
          position: "absolute",
          top: "-100%",
          left: "-100%",
          width: 1,
          height: 1,
        }}
        accept="image/*"
        onChange={(e) => {
          const files = e.target.files;
          if (!files) return;
          const file = files[0];

          const reader = new FileReader();

          let usage = refInputImageUsage.current;
          refInputImageUsage.current = "none";

          console.log("usage: ", usage);

          switch (usage) {
            case "logo":
              if (!file) {
                setLogoUrl("");
                return;
              }

              reader.onloadend = () => {
                setLogoUrl(reader.result as string);
                setSelectedLogo(file);
              };

              reader.readAsDataURL(file);

              break;

            case "img_add":
              if (!file) return;

              reader.onloadend = () => {
                if (refInputImagePosition.current === "swiper")
                  setSwiperImages((prev) => {
                    return [
                      ...prev,
                      { type: "new", file, data: reader.result as string },
                    ];
                  });
                else if (refInputImagePosition.current === "content") {
                  setContentImages((prev) => {
                    return [
                      ...prev,
                      { type: "new", file, data: reader.result as string },
                    ];
                  });
                }
              };

              reader.readAsDataURL(file);
              break;

            case "img_change":
              if (!file) return;

              reader.onloadend = () => {
                if (refInputImagePosition.current === "swiper")
                  setSwiperImages((prev) => {
                    const copied = prev.slice();
                    copied[refChangingImageIdx.current] = {
                      type: "new",
                      file,
                      data: reader.result as string,
                    };

                    refChangingImageIdx.current = -1;

                    return copied;
                  });
                else if (refInputImagePosition.current === "content")
                  setContentImages((prev) => {
                    const copied = prev.slice();
                    copied[refChangingImageIdx.current] = {
                      type: "new",
                      file,
                      data: reader.result as string,
                    };

                    refChangingImageIdx.current = -1;

                    return copied;
                  });
              };

              reader.readAsDataURL(file);

              break;
          }
        }}
      />
    </main>
  );
};

export default HouseFormPage;
