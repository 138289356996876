import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import axios from "../api/axios";
import { useRecoilState } from "recoil";
import { atom__me } from "../lib/recoil";

export const delay = (duration: number) =>
  new Promise((resolve) => setTimeout(resolve, duration));

const useAuth = () => {
  const [r__me, r__setMe] = useRecoilState(atom__me);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await axios.get("/user/me");
        if (!data) return;
        if (!data.success) return;
        r__setMe(data.data.user);
      } catch (error) {
        if (error instanceof AxiosError) {
          console.log(error.response?.data);
        } else console.log({ error });
      } finally {
      }
    };

    if (!r__me) fetchUser();
  }, [r__me]);

  return !!r__me;
};

export default useAuth;
