import React, { useEffect, useState } from "react";
import { api_deleteHouses, api_houseList } from "../api/house";
import { THouseListItem } from "../lib/types/house.type";
import { useNavigate } from "react-router-dom";

const HouseListPage = () => {
  const navigate = useNavigate();

  const [houses, setHouses] = useState<THouseListItem[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    api_houseList().then((res) => {
      if (!res) return;
      setHouses(res.list);
    });
  };

  const deleteHouses = async () => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return;

    api_deleteHouses(selectedIds).then((res) => {
      if (!res) return;
      console.log("list:: ", res.list);
      setHouses(res.list);
      setSelectedIds([]);
    });
  };

  return (
    <main>
      <h1>숙소관리</h1>

      <div>
        <button
          className={`bg-blue-400 text-white mr-4`}
          onClick={() => {
            navigate("/house/0");
          }}
        >
          추가
        </button>

        <button
          className={`${
            selectedIds.length === 0 ? "bg-gray-400" : "bg-red-400"
          } text-white`}
          disabled={selectedIds.length === 0}
          onClick={deleteHouses}
        >
          삭제
        </button>
      </div>

      <table className="w-full table-auto mt-5">
        <thead className="text-sm font-semibold text-left text-gray-800 border-b pb-2">
          <tr>
            <th>선택</th>
            <th>이름</th>
            <th>주소</th>
          </tr>
        </thead>

        <tbody>
          {houses.map((house) => (
            <tr
              key={house.id}
              onClick={(e) => {
                navigate(`/house/${house.id}`);
              }}
            >
              <td
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => {
                    const checked = e.currentTarget.checked;
                    if (checked) {
                      setSelectedIds((prev) => {
                        return [...prev, house.id];
                      });
                    } else {
                      setSelectedIds((prev) => {
                        const copied = prev.slice();
                        const idx = copied.findIndex((i) => i === house.id);
                        copied.splice(idx, 1);
                        return copied;
                      });
                    }
                  }}
                />
              </td>
              <td>{house.name}</td>
              <td>{house.address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};

export default HouseListPage;
