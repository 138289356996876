import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import Layout from "./components/Layout";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import HouseListPage from "./pages/HouseList";
import HouseFormPage from "./pages/HouseForm";
import NotFoundPage from "./pages/NotFoundPage";
import useAuth from "./hooks/useAuth";
import RoomFormPage from "./pages/RoomForm";
import UserListPage from "./pages/UserList";
import MainImageForm from "./pages/MainImageForm";
import QnaListPage from "./pages/QnaList";
import QnaDetailPage from "./pages/QnaDetail";
import TradeHistoryListPage from "./pages/TradeHistoryList";
import TradeHistoryDetailPage from "./pages/TradeHistoryDetail";
import WithdrawalListPage from "./pages/WithdrawalList";
function App() {
  // useAxios();
  const loggedIn = useAuth();

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LoginPage />} />

        <Route element={<RequireAuth />}>
          <Route path="/house" element={<HouseListPage />} />
          <Route path="/house/:id" element={<HouseFormPage />} />
          <Route
            path="/house/:houseId/room/:roomId"
            element={<RoomFormPage />}
          />

          <Route path="/user" element={<UserListPage />} />
          <Route path="/mainimage" element={<MainImageForm />} />

          <Route path="/trade" element={<TradeHistoryListPage />} />
          <Route
            path="/trade/:initialTicketId"
            element={<TradeHistoryDetailPage />}
          />

          <Route path="/qna" element={<QnaListPage />} />
          <Route path="/qna/:id" element={<QnaDetailPage />} />

          <Route path="/withdrawal" element={<WithdrawalListPage />} />
        </Route>
      </Route>
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
