export const ROOM_FEATURES = [
  "와이파이",
  "에어컨",
  "미니바",
  "세탁기",
  "주차공간",
  "TV",
  "난방",
  "드라이어",
  "다리미",
  "수영장",
  "욕조",
  "헬스장",
  "조식",
  "흡연",
  "화장대",
  "서재",
  "주방",
  "바베큐",
  "난로",
  "옷장",
  "스타일러",
  "신발장",
  "반려동물",
  "룸서비스",
];

export const ROOM_FEATURE_CHECKLIST = ROOM_FEATURES.reduce<
  Record<string, boolean>
>((acc, cur) => {
  acc[cur] = false;
  return acc;
}, {});
