import { Outlet } from "react-router-dom";
// import MenuBar from '../components/MenuBar'
import SideBar from "./SideBar";

const Layout = () => {
  return (
    <div className="">
      <div className="flex h-screen">
        <SideBar />
        <div className="flex flex-col flex-1 overflow-auto">
          {/* <MenuBar /> */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
