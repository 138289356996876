import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HouseImage from "../components/HouseImage";
import { ROOM_FEATURES, ROOM_FEATURE_CHECKLIST } from "../lib/constant";
import { api_saveUploadedImg, api_uploadImgToS3 } from "../api/common";
import { TRoomSavePayload } from "../lib/types/room.type";
import {
  api_roomDetail,
  api_saveRoom,
  api_saveRoomContents,
  api_saveRoomCosts,
} from "../api/room";
import Editor from "../components/Editor";

const RoomFormPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [title, setTitle] = useState("");

  const [roomId, setRoomId] = useState<number | null>(null);
  const [mode, setMode] = useState<"create" | "update">("create");

  const [roomName, setRoomName] = useState("");
  const [roomSize, setRoomSize] = useState("");
  const [checkinTime, setCheckinTime] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");
  const [peoplePivot, setPeoplePivot] = useState("");
  const [peopleLimit, setPeopleLimit] = useState("");

  const [cntBed1, setCntBed1] = useState("0");
  const [cntBed2, setCntBed2] = useState("0");
  const [cntBed3, setCntBed3] = useState("0");
  const [cntBed4, setCntBed4] = useState("0");
  const [cntBed5, setCntBed5] = useState("0");

  const [roomImages, setRoomImages] = useState<
    (
      | { type: "already"; id: number; url: string }
      | { type: "new"; file: File; data: string }
    )[]
  >([]);

  const [features, setFeatures] = useState<Record<string, boolean>>(
    ROOM_FEATURE_CHECKLIST
  );

  const [detailContents, setDetailContents] = useState<
    { id?: number; title: string; content: string }[]
  >([]);

  const [costs, setCosts] = useState<
    {
      startDate: string;
      endDate: string;
      weekdayCost: string;
      weekendCost: string;
    }[]
  >([]);

  const houseId = useMemo(() => {
    const houseIdNumber = Number(params.houseId);
    if (!houseIdNumber) {
      alert("비정상적인 접근입니다.");
      navigate("/house");
      return;
    }
    return houseIdNumber;
  }, [params]);

  const refInputImage = useRef<HTMLInputElement>(null);
  const refInputImageUsage = useRef<"img_add" | "img_change" | "none">("none");
  const refChangingImageIdx = useRef(-1);

  useEffect(() => {
    if (params.roomId && params.roomId !== "0") {
      const roomIdNumber = Number(params.roomId);
      setRoomId(roomIdNumber);
      setMode("update");
      setTitle("객실 정보");

      fetchDetail(roomIdNumber);
    } else {
      setTitle("객실 등록");
    }
  }, []);

  const fetchDetail = (id: number) => {
    api_roomDetail(id).then((res) => {
      if (!res) return;

      const { room, images, contents, costs: resCosts } = res;

      setRoomName(room.name);
      setCheckinTime(room.checkinTime);
      setCheckoutTime(room.checkoutTime);
      setRoomSize(room.roomSize + "");
      setPeoplePivot(room.peoplePivot + "");
      setPeopleLimit(room.peopleLimit + "");
      setCntBed1((room.cntBed1 ?? 0) + "");
      setCntBed2((room.cntBed2 ?? 0) + "");
      setCntBed3((room.cntBed3 ?? 0) + "");
      setCntBed4((room.cntBed4 ?? 0) + "");
      setCntBed5((room.cntBed5 ?? 0) + "");

      setFeatures((prev) => {
        const resFeatures = room.feature.split(",");
        return ROOM_FEATURES.reduce<Record<string, boolean>>((acc, cur) => {
          acc[cur] = resFeatures.includes(cur);
          return acc;
        }, {});
      });

      setRoomImages(
        images.map((img) => {
          return {
            type: "already",
            id: img.id,
            url: img.url,
          };
        })
      );

      setDetailContents(contents);

      setCosts(
        resCosts.map((c) => ({
          ...c,
          weekdayCost: c.weekdayCost + "",
          weekendCost: c.weekendCost + "",
        }))
      );
    });
  };

  const save = async () => {
    if (!houseId) return;

    const regCheckinTime = new RegExp(/^\d\d:\d\d$/);
    if (!roomName) return alert("객실 이름을 입력해주세요.");
    if (!checkinTime) return alert("체크인시간 입력해주세요.");
    if (!regCheckinTime.test(checkinTime))
      return alert("체크인시간은 00:00의 형식으로 입력해주세요.");
    if (!checkoutTime) return alert("체크아웃시간 입력해주세요.");
    if (!regCheckinTime.test(checkoutTime))
      return alert("체크아웃시간은 00:00의 형식으로 입력해주세요.");
    if (!peoplePivot) return alert("기준인원을 입력해주세요.");
    if (!peopleLimit) return alert("최대인원을 입력해주세요.");
    if (!roomSize) return alert("평수를 입력해주세요.");
    if (roomImages.length === 0)
      return alert("하나 이상의 이미지를 등록해주세요.");

    const joinedFeature = Object.entries(features)
      .filter((entry) => entry[1])
      .map((entry) => entry[0])
      .join(",");

    const uploadedImgs: { id: number; url: string; isNew: boolean }[] = [];
    for (const img of roomImages) {
      if (img.type === "new") {
        const url = await api_uploadImgToS3(img.file);
        if (!url) return;
        const createdId = await api_saveUploadedImg(url);
        if (!createdId) return;

        uploadedImgs.push({ id: createdId, url, isNew: true });
      } else uploadedImgs.push({ id: img.id, url: img.url, isNew: false });
    }

    const payload: TRoomSavePayload = {
      houseId,
      name: roomName,
      peoplePivot: Number(peoplePivot),
      peopleLimit: Number(peopleLimit),
      cntBed1: Number(cntBed1),
      cntBed2: Number(cntBed2),
      cntBed3: Number(cntBed3),
      cntBed4: Number(cntBed4),
      cntBed5: Number(cntBed5),
      roomSize: Number(roomSize),
      checkinTime,
      checkoutTime,
      feature: joinedFeature,

      roomImages: uploadedImgs,
    };

    if (roomId) payload.id = roomId;

    const res = await api_saveRoom(payload);
    if (!res) return;

    alert("저장되었습니다.");

    setRoomId(res.id);
    setMode("update");
    setTitle("객실 정보");
    setRoomImages(
      uploadedImgs.map((img) => ({ type: "already", id: img.id, url: img.url }))
    );
  };

  const saveContents = () => {
    if (!roomId) return alert("기본 정보를 먼저 저장해주세요.");
    if (!detailContents.length)
      return alert("하나 이상의 상세설명을 작성해주세요.");
    if (detailContents.find((c) => !c.content))
      return alert("상세설명의 내용은 비워둘 수 없습니다.");

    api_saveRoomContents(roomId, detailContents).then((res) => {
      if (!res) return;
      alert("저장되었습니다.");
    });
  };

  const saveCost = async () => {
    if (!roomId) return alert("기본 정보를 먼저 저장해주세요.");

    const regDate = new RegExp(/^\d\d\d\d-\d\d-\d\d$/);

    for (const cost of costs) {
      if (!cost.startDate) return alert("시작일을 입력해주세요.");
      if (!cost.endDate) return alert("종료일을 입력해주세요.");
      if (!regDate.test(cost.startDate) || !regDate.test(cost.endDate))
        return alert("유효하지 않은 기간입니다.");

      if (!cost.weekdayCost) return alert("주중 가격을 입력해주세요.");
      if (!cost.weekendCost) return alert("주말 가격을 입력해주세요.");
    }

    const payloadCosts = costs.map((c) => ({
      startDate: c.startDate,
      endDate: c.endDate,
      weekdayCost: Number(c.weekdayCost),
      weekendCost: Number(c.weekendCost),
    }));

    api_saveRoomCosts(roomId, payloadCosts).then((res) => {
      if (!res) return;
      alert("저장되었습니다.");
    });
  };

  return (
    <main>
      <h1>{title}</h1>

      {/* 기본 정보 */}
      <div className={"form-section"}>
        <div className="flex justify-between items-center">
          <h2>기본 정보</h2>
          <button className={`bg-blue-400 text-white`} onClick={save}>
            저장
          </button>
        </div>
        <h4>객실을 선택했을 때 가장 먼저 보여지는 정보입니다.</h4>

        <div style={{ marginTop: "20px" }}>
          <label htmlFor="room_name">객실 이름</label>
          <input
            className="border px-2 py-1 my-2 w-full"
            type="text"
            id="room_name"
            name="room_name"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
        </div>

        <div style={{ marginTop: "20px", display: "flex" }}>
          <div style={{ marginRight: "20px" }}>
            <label htmlFor="checkin_time">체크인</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="text"
              id="checkin_time"
              name="checkin_time"
              placeholder="14:00"
              value={checkinTime}
              onChange={(e) => setCheckinTime(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="checkout_time">체크아웃</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="text"
              id="checkout_time"
              name="checkout_time"
              placeholder="11:00"
              value={checkoutTime}
              onChange={(e) => setCheckoutTime(e.target.value)}
            />
          </div>
        </div>

        <div style={{ marginTop: "20px", display: "flex" }}>
          <div style={{ marginRight: "20px" }}>
            <label htmlFor="checkin_time">평수</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="number"
              step={1}
              id="checkin_time"
              name="checkin_time"
              placeholder=""
              value={roomSize}
              onChange={(e) => setRoomSize(e.target.value)}
            />
          </div>

          <div style={{ marginRight: "20px" }}>
            <label htmlFor="people_pivot">기준인원</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="number"
              step={1}
              id="people_pivot"
              name="people_pivot"
              placeholder="2"
              value={peoplePivot}
              onChange={(e) => setPeoplePivot(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="people_limit">최대인원</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="number"
              step={1}
              id="people_limit"
              name="people_limit"
              placeholder="4"
              value={peopleLimit}
              onChange={(e) => setPeopleLimit(e.target.value)}
            />
          </div>
        </div>

        <div style={{ marginTop: "20px", display: "flex", flexWrap: "wrap" }}>
          <div style={{ marginRight: "20px" }}>
            <label htmlFor="people_pivot">침대 - 싱글</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="number"
              step={1}
              id="people_pivot"
              name="people_pivot"
              placeholder="0"
              value={cntBed1}
              onChange={(e) => setCntBed1(e.target.value)}
            />
          </div>

          <div style={{ marginRight: "20px" }}>
            <label htmlFor="people_limit">침대 - 슈퍼싱글</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="number"
              step={1}
              id="people_limit"
              name="people_limit"
              placeholder="0"
              value={cntBed2}
              onChange={(e) => setCntBed2(e.target.value)}
            />
          </div>

          <div style={{ marginRight: "20px" }}>
            <label htmlFor="people_limit">침대 - 더블</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="number"
              step={1}
              id="people_limit"
              name="people_limit"
              placeholder="0"
              value={cntBed3}
              onChange={(e) => setCntBed3(e.target.value)}
            />
          </div>

          <div style={{ marginRight: "20px" }}>
            <label htmlFor="people_limit">침대 - 퀸</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="number"
              step={1}
              id="people_limit"
              name="people_limit"
              placeholder="0"
              value={cntBed4}
              onChange={(e) => setCntBed4(e.target.value)}
            />
          </div>

          <div style={{ marginRight: "20px" }}>
            <label htmlFor="people_limit">침대 - 킹</label>
            <input
              className="border px-2 py-1 my-2"
              style={{ display: "block" }}
              type="number"
              step={1}
              id="people_limit"
              name="people_limit"
              placeholder="0"
              value={cntBed5}
              onChange={(e) => setCntBed5(e.target.value)}
            />
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <div className={"mb-2"}>
            <label>편의시설</label>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {ROOM_FEATURES.map((f, i) => {
              const selected = features[f];
              return (
                <span
                  key={i}
                  onClick={() => {
                    setFeatures((prev) => {
                      return { ...prev, [f]: !prev[f] };
                    });
                  }}
                  className={`feature-box ${
                    selected ? "feature-box-selected" : ""
                  }`}
                >
                  {f}
                </span>
              );
            })}
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>이미지</label>
          <div className="horizontal-scroll-container my-2">
            {roomImages.map((roomImg, i) => {
              return (
                <HouseImage
                  key={i}
                  src={roomImg.type === "already" ? roomImg.url : roomImg.data}
                  onClickChange={() => {
                    refChangingImageIdx.current = i;

                    refInputImageUsage.current = "img_change";
                    refInputImage.current?.click();
                  }}
                  onClickDelete={() => {
                    setRoomImages((prev) => {
                      const copied = prev.slice();
                      copied.splice(i, 1);

                      return copied;
                    });
                  }}
                  style={{
                    marginRight: "14px",
                    width: "200px",
                    height: "200px",
                  }}
                />
              );
            })}

            <div
              className="house-image-container house-image-container-plus"
              style={{
                width: "200px",
                height: "200px",
              }}
              onClick={() => {
                refInputImageUsage.current = "img_add";
                refInputImage.current?.click();
              }}
            ></div>
          </div>
        </div>
      </div>

      {/* 상세 설명 */}
      <div className={"form-section"}>
        <div className="flex justify-between items-center">
          <h2>상세 설명</h2>
          <button className={`bg-blue-400 text-white`} onClick={saveContents}>
            저장
          </button>
        </div>
        <h4 style={{ marginBottom: "30px" }}>
          제공 이벤트 등 제공되는 상세정보를 입력해주세요.
        </h4>

        {detailContents.map((c, i) => {
          return (
            <div className="editor-container" key={i}>
              <div>
                <label>제목</label>
                <button
                  className={`bg-red-400 text-white ml-4`}
                  onClick={() => {
                    setDetailContents((prev) => {
                      const copied = prev.slice();
                      copied.splice(i, 1);
                      return copied;
                    });
                  }}
                >
                  삭제
                </button>
              </div>
              <input
                className="border px-2 py-1 my-2 w-full"
                type="text"
                name="house_content_title"
                value={c.title}
                onChange={(e) => {
                  setDetailContents((prev) => {
                    const copied = prev.slice();
                    copied[i].title = e.target.value;
                    return copied;
                  });
                }}
              />

              <div className="my-2">
                <label>내용</label>
              </div>
              <Editor
                id={`#editor${i}`}
                value={c.content}
                onChange={(v: string) => {
                  setDetailContents((prev) => {
                    const copied = prev.slice();
                    copied[i].content = v;
                    return copied;
                  });
                }}
              />
            </div>
          );
        })}
        <button
          className="bg-blue-400 text-white mt-5"
          onClick={() => {
            setDetailContents((prev) => [...prev, { title: "", content: "" }]);
          }}
        >
          추가
        </button>
      </div>

      {/* 가격 정보 */}
      <div className={"form-section"}>
        <div className="flex justify-between items-center">
          <h2>가격</h2>
          <button className={`bg-blue-400 text-white`} onClick={saveCost}>
            저장
          </button>
        </div>

        {costs.map((cost, i) => {
          return (
            <div className="flex items-center editor-container" key={i}>
              <div className="mr-4">
                <label>시작일</label>
                <input
                  type="date"
                  style={{ display: "block" }}
                  className="border px-2 py-1 my-2 w-[200px]"
                  value={cost.startDate}
                  onChange={(e) => {
                    setCosts((prev) => {
                      const copied = prev.slice();
                      copied[i].startDate = e.target.value;
                      return copied;
                    });
                  }}
                />
              </div>

              <div className="mr-4">
                <label>종료일 (* 종료일 당일까지 가격이 적용됩니다.)</label>
                <input
                  type="date"
                  style={{ display: "block" }}
                  className="border px-2 py-1 my-2 w-[200px]"
                  value={cost.endDate}
                  onChange={(e) => {
                    setCosts((prev) => {
                      const copied = prev.slice();
                      copied[i].endDate = e.target.value;
                      return copied;
                    });
                  }}
                />
              </div>

              <div className="mr-4">
                <label>주중 가격</label>
                <input
                  type="number"
                  step={1}
                  style={{ display: "block" }}
                  className="border px-2 py-1 my-2 w-[200px]"
                  value={cost.weekdayCost}
                  onChange={(e) => {
                    setCosts((prev) => {
                      const copied = prev.slice();
                      copied[i].weekdayCost = e.target.value;
                      return copied;
                    });
                  }}
                />
              </div>
              <div className="mr-4">
                <label>주말 가격</label>
                <input
                  type="number"
                  step={1}
                  style={{ display: "block" }}
                  className="border px-2 py-1 my-2 w-[200px]"
                  value={cost.weekendCost}
                  onChange={(e) => {
                    setCosts((prev) => {
                      const copied = prev.slice();
                      copied[i].weekendCost = e.target.value;
                      return copied;
                    });
                  }}
                />
              </div>

              <button
                className={`bg-red-400 text-white ml-4`}
                onClick={() => {
                  setCosts((prev) => {
                    const copied = prev.slice();
                    copied.splice(i, 1);
                    return copied;
                  });
                }}
              >
                삭제
              </button>
            </div>
          );
        })}

        <button
          className="bg-blue-400 text-white mt-5"
          onClick={() => {
            setCosts((prev) => [
              ...prev,
              { startDate: "", endDate: "", weekdayCost: "", weekendCost: "" },
            ]);
          }}
        >
          추가
        </button>
      </div>

      <input
        type="file"
        ref={refInputImage}
        name="house_logo"
        style={{
          position: "absolute",
          top: "-100%",
          left: "-100%",
          width: 1,
          height: 1,
        }}
        accept="image/*"
        onChange={(e) => {
          const files = e.target.files;
          if (!files) return;
          const file = files[0];

          const reader = new FileReader();

          let usage = refInputImageUsage.current;
          refInputImageUsage.current = "none";

          console.log("usage: ", usage);

          switch (usage) {
            case "img_add":
              if (!file) return;

              reader.onloadend = () => {
                setRoomImages((prev) => {
                  return [
                    ...prev,
                    { type: "new", file, data: reader.result as string },
                  ];
                });
              };

              reader.readAsDataURL(file);
              break;

            case "img_change":
              if (!file) return;

              reader.onloadend = () => {
                setRoomImages((prev) => {
                  const copied = prev.slice();
                  copied[refChangingImageIdx.current] = {
                    type: "new",
                    file,
                    data: reader.result as string,
                  };

                  refChangingImageIdx.current = -1;

                  return copied;
                });
              };

              reader.readAsDataURL(file);

              break;
          }
        }}
      />
    </main>
  );
};

export default RoomFormPage;
