import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { useRecoilValue } from "recoil";
import { atom__me } from "../lib/recoil";

const RequireAuth = () => {
  const r__me = useRecoilValue(atom__me);

  if (!r__me) return <Navigate to="/login" />;
  return <Outlet />;
};

export default RequireAuth;
