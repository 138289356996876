import React, { useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { api_uploadImgToS3 } from "../api/common";

interface IProps {
  id: string;
  value: string;
  onChange: (v: string) => void;
}
const Editor = (props: IProps) => {
  const quillRef = useRef<ReactQuill>(null);

  const imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/jpg, image/jpeg, image/png");
    document.body.appendChild(input);

    input.click();

    input.onchange = async () => {
      if (!input.files) return;
      if (!quillRef.current) return;

      const file = input.files[0];

      const url = await api_uploadImgToS3(file);

      // 현재 커서 위치에 이미지를 삽입하고 커서 위치를 +1 하기
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();

      if (range) {
        quillRef.current.getEditor().insertEmbed(range.index, "image", url);
        quillRef.current.getEditor().setSelection(range.index + 1, 1);
      }
      // document.body.querySelector(':scope > input').remove()
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, false] }],
          // ["image"],
          ["bold", "underline"],
          [{ list: "bullet" }, { list: "ordered" }],
        ],
        handlers: { image: imageHandler },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      syntax: false,
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = useMemo(
    () => [
      "align",
      "background",
      "blockquote",
      "bold",
      "bullet",
      "code",
      "code-block",
      "color",
      "direction",
      "font",
      "formula",
      "header",
      "image",
      "indent",
      "italic",
      "link",
      "list",
      "script",
      "size",
      "strike",
      "table",
      "underline",
      "video",
    ],
    []
  );

  return (
    <div style={{ height: "300px" }}>
      <ReactQuill
        ref={quillRef}
        id={props.id}
        theme="snow"
        value={props.value}
        onChange={props.onChange}
        modules={modules}
        formats={formats}
        style={{ height: "250px" }}
      />
    </div>
  );
};

export default Editor;
