import { TQna, TQnaDetailRes, TQnaListItem } from "../lib/types/qna.type";
import axios from "./axios";

export const api_qnaList = async () => {
  const res = await axios.get("/qna/adm");

  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data.list as TQnaListItem[];
};
export const api_qnaDetail = async (qnaId: number) => {
  const res = await axios.get("/qna/adm/" + qnaId);

  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as TQnaDetailRes;
};

export const api_qnaAnswer = async (qnaId: number, answer: string) => {
  const res = await axios.post("/qna/" + qnaId + "/answer", { answer });

  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as true;
};
