import React, { useEffect, useState } from "react";

interface IProps {
  src: string;
  onClickChange: () => void;
  onClickDelete: () => void;
  style?: React.CSSProperties;
}
const HouseImage = (props: IProps) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="house-image-container"
      style={{ width: "130px", height: "130px", ...(props.style ?? {}) }}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <img
        src={props.src}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      {hover && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          <div
            className="flex flex-1 justify-center items-center h-full bg-blue-400"
            onClick={(e) => {
              props.onClickChange();
            }}
          >
            변경
          </div>
          <div
            className="flex flex-1 justify-center items-center h-full bg-red-400"
            onClick={(e) => {
              props.onClickDelete();
            }}
          >
            삭제
          </div>
        </div>
      )}
    </div>
  );
};

export default HouseImage;
