import React, { useEffect, useRef, useState } from "react";
import { api_mainImageList, api_saveMainImages } from "../api/image";
import { api_saveUploadedImg, api_uploadImgToS3 } from "../api/common";

const MainImageForm = () => {
  const [images, setImages] = useState<
    (
      | { type: "already"; id: number; url: string }
      | { type: "new"; file: File; data: string }
    )[]
  >([]);

  const refInputImage = useRef<HTMLInputElement>(null);
  const refInputImageUsage = useRef<"img_add" | "img_change" | "none">("none");
  const refChangingImageIdx = useRef(-1);

  useEffect(() => {
    api_mainImageList().then((list) => {
      if (!list) return;
      setImages(
        list.map((img) => ({ type: "already", id: img.id, url: img.url }))
      );
    });
  }, []);

  const save = async () => {
    const uploadedImgs: { id: number; url: string; isNew: boolean }[] = [];
    for (const img of images) {
      if (img.type === "new") {
        const url = await api_uploadImgToS3(img.file);
        if (!url) return;
        const createdId = await api_saveUploadedImg(url);
        if (!createdId) return;

        uploadedImgs.push({ id: createdId, url, isNew: true });
      } else uploadedImgs.push({ id: img.id, url: img.url, isNew: false });
    }

    api_saveMainImages(uploadedImgs).then((res) => {
      if (!res) return;

      alert("저장되었습니다.");
    });
  };

  return (
    <main>
      <h1>메인이미지관리</h1>
      <h4 className="desc">
        추가/변경/삭제 등 변경사항은 '저장' 버튼을 눌러야 반영됩니다.
      </h4>

      <div className="my-10">
        <button className={`bg-blue-400 text-white`} onClick={save}>
          저장
        </button>
      </div>

      {images.map((mainImg, i) => {
        return (
          <div
            key={i}
            className="my-4"
            style={{ borderBottom: "2px solid #eee", padding: "20px 0" }}
          >
            <div className="my-2">
              <label className="label-default">{i + 1}번째 이미지</label>

              <button
                className={`bg-blue-500 text-white ml-4`}
                onClick={() => {
                  refChangingImageIdx.current = i;

                  refInputImageUsage.current = "img_change";
                  refInputImage.current?.click();
                }}
              >
                변경
              </button>

              <button
                className={`bg-red-400 text-white ml-4`}
                onClick={() => {
                  setImages((prev) => {
                    const copied = prev.slice();
                    copied.splice(i, 1);

                    return copied;
                  });
                }}
              >
                삭제
              </button>
            </div>
            <img
              src={mainImg.type === "already" ? mainImg.url : mainImg.data}
            />
          </div>
        );
      })}

      <button
        className="bg-blue-400 text-white mt-5"
        onClick={() => {
          if (images.length === 8)
            return alert("최대 8장까지 등록할 수 있습니다.");

          refInputImageUsage.current = "img_add";
          refInputImage.current?.click();
        }}
      >
        추가
      </button>

      <input
        type="file"
        ref={refInputImage}
        name="house_logo"
        style={{
          position: "absolute",
          top: "-100%",
          left: "-100%",
          width: 1,
          height: 1,
        }}
        accept="image/*"
        onChange={(e) => {
          const files = e.target.files;
          if (!files) return;
          const file = files[0];

          const reader = new FileReader();

          let usage = refInputImageUsage.current;
          refInputImageUsage.current = "none";

          switch (usage) {
            case "img_add":
              if (!file) return;

              reader.onloadend = () => {
                setImages((prev) => {
                  return [
                    ...prev,
                    { type: "new", file, data: reader.result as string },
                  ];
                });
              };

              reader.readAsDataURL(file);
              break;

            case "img_change":
              if (!file) return;

              reader.onloadend = () => {
                setImages((prev) => {
                  const copied = prev.slice();
                  copied[refChangingImageIdx.current] = {
                    type: "new",
                    file,
                    data: reader.result as string,
                  };

                  refChangingImageIdx.current = -1;

                  return copied;
                });
              };

              reader.readAsDataURL(file);

              break;
          }
        }}
      />
    </main>
  );
};

export default MainImageForm;
