import React, { useEffect, useState } from "react";
import { api_deleteHouses, api_houseList } from "../api/house";
import { THouseListItem } from "../lib/types/house.type";
import { useNavigate } from "react-router-dom";
import { TQnaListItem } from "../lib/types/qna.type";
import { api_qnaList } from "../api/qna";

const QnaListPage = () => {
  const navigate = useNavigate();

  const [qnaList, setQnaList] = useState<TQnaListItem[]>([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    api_qnaList().then((list) => {
      if (!list) return;
      setQnaList(list);
    });
  };

  return (
    <main>
      <h1>문의관리</h1>

      <table className="w-full table-auto mt-5">
        <thead className="text-sm font-semibold text-left text-gray-800 border-b pb-2">
          <tr>
            <th>제목</th>
            <th>유저이름</th>
            <th>접수일자</th>
            <th>답변완료</th>
          </tr>
        </thead>

        <tbody>
          {qnaList.map((qna) => (
            <tr
              key={qna.id}
              onClick={(e) => {
                navigate(`/qna/${qna.id}`);
              }}
            >
              <td>{qna.subject}</td>
              <td>{qna.userName}</td>
              <td>{qna.createdAt}</td>
              <td>{qna.isResolved ? "O" : "X"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};

export default QnaListPage;
