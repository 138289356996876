import { TTradeHistoryListItem } from "../lib/types/ticket.type";
import axios from "./axios";

export const api_tradeHistoryList = async () => {
  const res = await axios.get(`/ticket/adm/trade-history`);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data.list as TTradeHistoryListItem[];
};

export const api_tradeHistoryDetail = async (initialTicketId: number) => {
  const res = await axios.get(`/ticket/adm/trade-history/${initialTicketId}`);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as {
    summary: TTradeHistoryListItem;
    histories: {
      date: string;
      amount: string;
      soldAmount: string;
      owner: { id: number; name: string };
      buyer: { id: number; name: string } | null;
    }[];
  };
};
