import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

type Props = {
  item: { id: number; name: string; icon: IconDefinition; path: string };
  isActive: boolean;
};

function SideBarItem({ item, isActive }: Props) {
  return (
    <li
      className={
        isActive
          ? "relative flex-row items-center bg-gray-200 after:absolute after:w-2 after:h-full after:top-0 after:left-0 after:bg-gray-500"
          : ""
      }
    >
      <Link
        className="text-lg w-full block py-4 pl-10 text-[#323232]"
        to={item.path}
      >
        <FontAwesomeIcon className="mr-7" icon={item.icon} color="#323232" />
        <span>{item.name}</span>
      </Link>
    </li>
  );
}

export default SideBarItem;
