import React, { useEffect, useState } from "react";

interface IProps {
  text: string;
  onClick: () => void;
}
const HouseTag = (props: IProps) => {
  const [hover, setHover] = useState(false);

  return (
    <span
      className="house-tag"
      onClick={props.onClick}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {props.text}
      {hover && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#000000bb",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          삭제
        </div>
      )}
    </span>
  );
};

export default HouseTag;
