import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TUserListItem } from "../lib/types/user.type";
import { printFormattedDate } from "../lib/utils";
import { api_userList } from "../api/user";

const UserListPage = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState<TUserListItem[]>([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    api_userList().then((res) => {
      if (!res) return;
      setUsers(res.users);
    });
  };

  return (
    <main>
      <h1>유저 관리</h1>

      <table className="w-full table-auto mt-5">
        <thead className="text-sm font-semibold text-left text-gray-800 border-b pb-2">
          <tr>
            <th>No.</th>
            <th>이름</th>
            <th>휴대폰번호</th>
            <th>가입일</th>
          </tr>
        </thead>

        <tbody>
          {users.map((user, i) => (
            <tr
              key={i}
              // onClick={(e) => {
              //   navigate(`/house/${house.id}`);
              // }}
            >
              <td>{users.length - i}</td>
              <td>{user.name}</td>
              <td>{user.phone}</td>
              <td>
                {printFormattedDate(new Date(user.createdAt), {
                  seperator: "-",
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};

export default UserListPage;
