import {
  faArrowRightFromBracket,
  faHospital,
  faIdCard,
  faUserDoctor,
  faList,
  faTruckMedical,
  faHouse,
  faTableList,
  faHeadset,
  faDownload,
  faBuilding,
  faUser,
  faImage,
  faComments,
  faHands,
  faHandshakeSimple,
  faMoneyBill,
  faMoneyBill1,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";

export const sideBarItem = [
  // { id: 1, name: "홈", icon: faHouse, path: "#" },
  {
    id: 2,
    name: "숙소관리",
    icon: faBuilding,
    path: "/house",
  },
  {
    id: 3,
    name: "유저관리",
    icon: faUser,
    path: "/user",
  },
  {
    id: 4,
    name: "숙박권 거래내역",
    icon: faHandshakeSimple,
    path: "/trade",
  },
  {
    id: 5,
    name: "메인이미지관리",
    icon: faImage,
    path: "/mainimage",
  },
  {
    id: 6,
    name: "문의관리",
    icon: faComments,
    path: "/qna",
  },
  {
    id: 7,
    name: "예치금 인출신청 내역",
    icon: faDollar,
    path: "/withdrawal",
  },
];
