import { TUserListItem } from "../lib/types/user.type";
import axios from "./axios";

export const api_signin = async (payload: { uid: string; pw: string }) => {
  const res = await axios.post("/user/adm/signin", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as { accessToken: string; adm: { id: number } };
};

export const api_logout = async () => {
  const res = await axios.post("/user/adm/signin", null);
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data;
};

export const api_userList = async () => {
  const res = await axios.get("/user/adm");
  if (!res) return;
  if (!res.data.success) return alert(res.data.errorMessage);

  return res.data.data as { users: TUserListItem[] };
};
